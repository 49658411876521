<template>
  <div class="page-wrapper chiller-theme" style="margin-top: 71px">
    <main class="page-content w-99" style="height: 100vh">
      <div class="container-fluid row" style="height: 10%">
        <ul
          class="breadcrumb ProximaNovaRegular col"
          style="margin-left: 52px; margin-top: 12px"
        >
          <li>
            <router-link to="/solutions/in/platform/models">
              <span>Model</span>
            </router-link>
          </li>
          <li>
            <router-link to="/solutions/in/platform/stat">
              <span>Sale Statistics</span>
            </router-link>
          </li>
          <li>
            <router-link to="/solutions/in/platform/insights">
              <span>Future Planning</span>
            </router-link>
          </li>
          <li>
            <router-link to="/solutions/in/platform/future/planning-result">
              <span>Planning Result</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold">Scenerio planning</li>
        </ul>
        <!--<div
          class="col-md-2 colo-lg-2 d-flex justify-center"
          v-if="activeTab === 'Additional Details'"
        >
          <div class="w-100 py-4">
            <w-dropdown
              :options="marketTypedropdown"
              :placeHolder="'Select'"
              :labelText="'Market type'"
              class="mr-3"
              textWidth="50%"
              dropdownWidth="50%"
              bgColor="#fff"
              labelColor="#727988"
              boxShadow="box-shadow: 0 0 8px 0 #c9ced5;"
            ></w-dropdown>
          </div>
        </div>-->
      </div>

      <!-- tab -->
      <div class="container-fluid" style="height: 10%">
        <w-tab
          :tabName="activeTab"
          :tabs="tabs"
          @activeTab="changeTab($event)"
        >
        </w-tab>
      </div>
      <!-- Digital -->
      <div
        class="w-100 pb-4 prediction-section"
        v-if="activeTab === 'Digital Media'"
        style="height: 100%"
      >
        <div class="w-100" style="">
          <div
            class="container-fluid tab-panel m-0 p-0"
            style="margin-top: 10px;"
          >
            <div
              class="card w-100"
              style="box-shadow: rgb(233 235 239) 1px 2px 13px 5px;"
            >
              <div class="card-body p-4">
                <div
                  class="card-header font-large ProximaNovaBold"
                  style="
                        padding: 0;
                        background: transparent;
                        border: 0px !important;
                      "
                >
                  Prediction
                  <div
                    class="float-right text-danger text-normal ProximaNovaRegular"
                  >
                    <span v-if="levelOneChecking.value !== 100"
                      >* Total growth is {{ levelOneChecking.value }} in
                      {{ levelOneChecking.name }}
                    </span>
                    <span v-else-if="checkLevelTwo.value !== 100"
                      >* Total growth is {{ checkLevelTwo.value }} in
                      {{ checkLevelTwo.name }}
                    </span>
                    <span v-else-if="checkLevelThree.value !== 100"
                      >* Total growth is {{ checkLevelThree.value }} in
                      {{ checkLevelThree.name }}
                    </span>
                    <span v-else>* Total growth is 100</span>
                  </div>
                  <div class="caption mt-3">
                    <span
                      :class="
                        breadcrumb.first !== '' &&
                        breadcrumb.second === '' &&
                        breadcrumb.third === ''
                          ? 'text-bold'
                          : ''
                      "
                      >{{ breadcrumb.first }}</span
                    >
                    <span
                      v-if="breadcrumb.second"
                      :class="
                        breadcrumb.first !== '' &&
                        breadcrumb.second !== '' &&
                        breadcrumb.third === ''
                          ? 'text-bold'
                          : ''
                      "
                      ><i class="fa fa-chevron-right mx-3"></i
                      >{{ breadcrumb.second }}</span
                    >
                    <span
                      v-if="breadcrumb.third"
                      :class="
                        breadcrumb.first !== '' &&
                        breadcrumb.second !== '' &&
                        breadcrumb.third !== ''
                          ? 'text-bold'
                          : ''
                      "
                      ><i class="fa fa-chevron-right mx-3"></i
                      >{{ breadcrumb.third }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="container-fluid tab-panel card__body_content pt-5"
          style="height: 90%"
        >
          <div class="row fix-width">
            <div class="col p-0">
              <div
                @click="toggleOpenButton(i)"
                class="newcard"
                :class="[
                  i === activeGraph.activeFirst
                    ? 'border border-dark'
                    : 'disabled_card',
                  activeGraph.activeFirst === null ? 'default' : '',

                  i === activeGraph.activeFirst &&
                  info.data &&
                  info.data.length > 0
                    ? 'border_right_side'
                    : '',
                ]"
                v-for="(info, i) in levelOne"
                :key="i"
              >
                <div class="mb-4" style="padding: 0px 12px;">
                  <span class="slider_text">
                    <img
                      v-if="info.title === 'Instagram'"
                      src="@/assets/Icons/insta.svg"
                      alt=""
                      class="mr-2"
                    />
                    <img
                      v-if="info.title === 'DV360'"
                      src="@/assets/Icons/bitmap.png"
                      alt=""
                      class="mr-2"
                    />
                    <img
                      v-if="info.title === 'Linkedin'"
                      src="@/assets/Icons/linkedin.svg"
                      alt=""
                      class="mr-2"
                    />
                    <img
                      v-if="info.title === 'Twitter'"
                      src="@/assets/Icons/twitter.png"
                      alt=""
                      class="mr-2"
                    />
                    {{ info.title }}
                  </span>
                  <span class="float-right slider_button_right">
                    <img
                      v-if="!showMap[i]"
                      class="eye-line"
                      src="@/assets/eye-close-line.svg"
                      alt=""
                    />
                    <img
                      v-else
                      class="eye-line"
                      src="@/assets/eye-line.png"
                      alt=""
                    />
                  </span>
                </div>
                <w-activitychart
                  :sliderValue="info.value"
                  :color="info.color"
                  @sliderChanged="getSliderValue($event, info)"
                ></w-activitychart>
                <div
                  class="divider"
                  v-if="
                    i === activeGraph.activeFirst &&
                      levelOne[activeGraph.activeFirst].data
                  "
                  :style="
                    activeGraph.activeSecond === 0
                      ? `height:0`
                      : `height:` + 336 * activeGraph.activeSecond + `px;`
                  "
                ></div>
              </div>
            </div>
            <div class="col p-0" style="margin-top: 4px;">
              <div v-if="activeGraph.activeFirst !== null">
                <div
                  @click="toggleOpenSubButton(index)"
                  class="newcard d-block position-relative"
                  :style="
                    activeGraph.activeFirst === 0
                      ? `top:0`
                      : `top:` + 336 * activeGraph.activeFirst + `px;`
                  "
                  :class="[
                    index === activeGraph.activeSecond
                      ? 'border border-dark border_left_side'
                      : 'disabled_card',
                    activeGraph.activeSecond === null ? 'default' : '',

                    index === activeGraph.activeSecond &&
                    infoData.data &&
                    infoData.data.length > 0
                      ? 'border_right_side'
                      : '',
                  ]"
                  v-for="(infoData, index) in levelOne[activeGraph.activeFirst]
                    .data"
                  :key="index"
                >
                  <div class="mb-4" style="padding: 0px 12px;">
                    <span class="slider_text">
                      <img
                        v-if="infoData.title === 'Instagram'"
                        src="@/assets/Icons/insta.svg"
                        alt=""
                        class="mr-2"
                      />
                      <img
                        v-if="infoData.title === 'DV360'"
                        src="@/assets/Icons/bitmap.png"
                        alt=""
                        class="mr-2"
                      />
                      <img
                        v-if="infoData.title === 'Linkedin'"
                        src="@/assets/Icons/linkedin.svg"
                        alt=""
                        class="mr-2"
                      />
                      <img
                        v-if="infoData.title === 'Twitter'"
                        src="@/assets/Icons/twitter.png"
                        alt=""
                        class="mr-2"
                      />
                      {{ infoData.title }}
                    </span>
                    <span class="float-right slider_button_right">
                      <img
                        v-if="showMapSub[index]"
                        class="eye-line"
                        src="@/assets/eye-line.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="eye-line"
                        src="@/assets/eye-close-line.svg"
                        alt=""
                      />
                    </span>
                  </div>
                  <w-activitychart
                    :sliderValue="infoData.value"
                    :color="infoData.color"
                    @sliderChanged="getSliderValueLevelTwo($event, infoData)"
                  ></w-activitychart>
                </div>
              </div>
            </div>
            <div class="col p-0" style="margin-top: 10px;">
              <div v-if="activeGraph.activeSecond !== null">
                <div
                  class="newcard d-block position-relative"
                  :style="subsubStyle"
                  v-for="(infoData, index) in levelOne[activeGraph.activeFirst]
                    .data[activeGraph.activeSecond].data"
                  :key="index"
                  :class="index === 0 ? 'border_left_side' : ''"
                  style="border: 2px solid #fff"
                >
                  <div class="mb-4" style="padding: 0px 12px;">
                    <span class="slider_text">
                      {{ infoData.title }}
                    </span>
                  </div>
                  <w-activitychart
                    :sliderValue="infoData.value"
                    :color="infoData.color"
                    @sliderChanged="getSliderValueLevelThree($event, infoData)"
                  ></w-activitychart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <w-scenarioplanning
        v-if="activeTab === 'Additional Details'"
      ></w-scenarioplanning>
      <div class="float-right bottom_buttom" style="height: 10%">
        <w-button
          :buttonText="'Predict'"
          class="mb-5"
          :buttonLoader="'normal'"
          :isDisable="isActive === 'active' ? false : true"
          :class="{ resetPasswordLink: !isActive }"
          @buttonClicked="handleClick"
        >
        </w-button>
      </div>
    </main>
  </div>
</template>

<script>
import Tab from "@/components/Solutions/Tab.vue";
import ActivityChart from "@/components/Chart/ActivityChart.vue";
import AdditionalDetails from "@/pages/Scenario/AdditionalDetails.vue";
import Button from "@/components/Profile/Button.vue";
// import Dropdown from "@/widgets/Dropdown.vue";
// import { color } from 'highcharts';

export default {
  name: "ScenarioPlanning",
  components: {
    "w-tab": Tab,
    "w-activitychart": ActivityChart,
    "w-scenarioplanning": AdditionalDetails,
    // "w-dropdown": Dropdown,
    "w-button": Button,
  },
  data() {
    return {
      activelevelOne: "",
      activeLevelTwo: "",
      marketTypedropdown: [
        { text: "USA", id: "1" },
        { text: "INDIA", id: "2" },
        { text: "UK", id: "3" },
      ],
      breadcrumb: {
        first: "Channel",
        second: "",
        third: "",
      },
      activeTab: "Digital Media",
      activeGraph: {
        activeFirst: null,
        activeSecond: null,
      },
      tabs: [
        {
          category: "Digital Media",
        },
        // {
        //   category: "Content",
        // },
        {
          category: "Additional Details",
        },
      ],
      showMap: [],
      showMapSub: [],
      levelOne: [],
      subdata: [
        {
          id: 1,
          title: "Instagram",
          value: 20,
          color: "#1877f2",
          data: [
            {
              id: 1,
              title: "Engagement Campaigns",
              value: 25,
              color: "#b5a9ff",
            },
            {
              id: 2,
              title: "VIdeo Campaigns",
              value: 30,
              color: "#ffdd7c",
            },
            {
              id: 3,
              title: "Lead Ads",
              value: 41,
              color: "#67e8ff",
            },
          ],
        },
        {
          id: 2,
          title: "DV360",
          value: 50,
          color: "#dd2a7b",
          data: [
            {
              id: 1,
              title: "Engagement Campaigns",
              value: 25,
              color: "#b5a9ff",
              data: [
                {
                  id: 1,
                  title: "Lead Ads",
                  value: 50,
                  color: "#b5a9ff",
                },
                {
                  id: 2,
                  title: "Lead Ads",
                  value: 41,
                  color: "#ffdd7c",
                },
              ],
            },
            {
              id: 2,
              title: "VIdeo Campaigns",
              value: 30,
              color: "#ffdd7c",
              data: [
                {
                  id: 1,
                  title: "Lead Ads",
                  value: 50,
                  color: "#b5a9ff",
                },
                {
                  id: 2,
                  title: "Lead Ads",
                  value: 41,
                  color: "#ffdd7c",
                },
              ],
            },
            {
              id: 3,
              title: "Lead Ads",
              value: 41,
              color: "#67e8ff",
              data: [
                {
                  id: 1,
                  title: "Lead Ads",
                  value: 50,
                  color: "#b5a9ff",
                },
                {
                  id: 2,
                  title: "Lead Ads",
                  value: 41,
                  color: "#ffdd7c",
                },
              ],
            },
          ],
        },
        {
          id: 3,
          title: "Linkedin",
          value: 60,
          color: "#148f3a",
        },
        {
          id: 4,
          title: "Twitter",
          value: 80,
          color: "#0077b5",
        },
        {
          id: 5,
          title: "Instagram",
          value: 75,
          color: "#08a0e9",
        },
      ],
      colors: [
        "#ff5252",
        "#ffab40",
        "#40c4ff",
        "#7c4dff",
        "#ff4081",
        "#ff5252",
        "#ffab40",
        "#40c4ff",
        "#7c4dff",
        "#ff4081",
        "#ff5252",
        "#ffab40",
        "#40c4ff",
        "#7c4dff",
        "#ff4081",
        "#ff5252",
        "#ffab40",
        "#40c4ff",
        "#7c4dff",
        "#ff4081",
        "#ff5252",
        "#ffab40",
        "#40c4ff",
        "#7c4dff",
        "#ff4081",
      ],
      levelOneDataEdit: [],
      digitalDataLevelTwo: [],
      contentDataLevelTwo: [],
      contentDataThirdLevel: [],
      digitalDataThirdLevel: [],
      tvDataThirdLevel:[],
      tvDataLevelTwo:[],
      sponsorshipDataLevelTwo:[],
      sponsorshipDataThirdLevel:[],
      printDataLevelTwo:[],
      printDataThirdLevel:[],
      radioDataLevelTwo:[],
      radioDataThirdLevel:[],
      billboardDataLevelTwo:[],
      billboardDataThirdLevel:[]
    };
  },
  mounted() {
    const digitalData = this.$route.params.levelOneData;
    const tvLevelTwo = this.$route.params.tvData
    const sponsorshipLevelTwo = this.$route.params.sponsorshipData
    const printLevelTwo = this.$route.params.printData
    const radioLevelTwo = this.$route.params.radioData
    const billboardLevelTwo = this.$route.params.billboardData
    const digitalLevelTwo = this.$route.params.digitalData;
    const contentLevelTwo = this.$route.params.contentData;
    const contentThirdLevelData = this.$route.params.contentThirdLevelData;
    const digitalThirdLevelData = this.$route.params.digitalThirdLevelData;
    const tvThirdLevelData = this.$route.params.tvThirdLevelData;
    const sponsorshipThirdLevelData = this.$route.params.sponsorshipThirdLevelData;
    const printThirdLevelData = this.$route.params.printThirdLevelData;
    const radioThirdLevelData = this.$route.params.radioThirdLevelData;
    const billboardThirdLevelData = this.$route.params.billboardThirdLevelData;
    this.tvDataThirdLevel = tvThirdLevelData;
    this.sponsorshipDataThirdLevel = sponsorshipThirdLevelData;
    this.printDataThirdLevel = printThirdLevelData;
    this.radioDataThirdLevel = radioThirdLevelData;
    this.billboardDataThirdLevel = billboardThirdLevelData;
    this.digitalDataThirdLevel = digitalThirdLevelData;
    this.contentDataThirdLevel = contentThirdLevelData;
    this.digitalDataLevelTwo = digitalLevelTwo;
    this.tvDataLevelTwo = tvLevelTwo
    this.sponsorshipDataLevelTwo = sponsorshipLevelTwo
    this.printDataLevelTwo = printLevelTwo
    this.radioDataLevelTwo = radioLevelTwo
    this.billboardDataLevelTwo = billboardLevelTwo
    this.contentDataLevelTwo = contentLevelTwo;
    this.levelOneDataEdit = digitalData;
    let data = [];
    var secondLevelData = [];
    digitalData &&
      digitalData.forEach((ele, index) => {
        var i = 0;
        var j = 0;
        var dataLevelThree;
        if (ele.name === "Digital") {
          secondLevelData = [];
          for (i = 0; i < digitalLevelTwo.length; i++) {
            let levelthree = digitalThirdLevelData.find(
              (obj) => obj.title === digitalLevelTwo[i].label
            ).value;
            dataLevelThree = [];
            for (j = 0; j < levelthree.length; j++) {
              dataLevelThree.push({
                id: 1 + i + 1 + j + "digital",
                title: levelthree[j].name,
                value: levelthree[j].value,
                color: this.colors[j],
              });
            }
            secondLevelData.push({
              id: 1 + i + 1 + "digital",
              title: digitalLevelTwo[i].label,
              value: digitalLevelTwo[i].value,
              color: digitalLevelTwo[i].backgroundColor,
              data: dataLevelThree,
            });
          }
        }else if (ele.name === "Tv") {
          secondLevelData = [];
          for (i = 0; i < tvLevelTwo.length; i++) {
            let levelthree = tvThirdLevelData.find(
              (obj) => obj.title === tvLevelTwo[i].label
            ).value;
            dataLevelThree = [];
            for (j = 0; j < levelthree.length; j++) {
              dataLevelThree.push({
                id: 1 + i + 1 + j + "tv",
                title: levelthree[j].name,
                value: levelthree[j].value,
                color: this.colors[j],
              });
            }
            secondLevelData.push({
              id: 1 + i + 1 + "tv",
              title: tvLevelTwo[i].label,
              value: tvLevelTwo[i].value,
              color: tvLevelTwo[i].backgroundColor,
              data: dataLevelThree,
            });
          }
        }else if (ele.name === "Sponsorships") {
          secondLevelData = [];
          for (i = 0; i < sponsorshipLevelTwo.length; i++) {
            let levelthree = sponsorshipThirdLevelData.find(
              (obj) => obj.title === sponsorshipLevelTwo[i].label
            ).value;
            dataLevelThree = [];
            for (j = 0; j < levelthree.length; j++) {
              dataLevelThree.push({
                id: 1 + i + 1 + j + "sponsorship",
                title: levelthree[j].name,
                value: levelthree[j].value,
                color: this.colors[j],
              });
            }
            secondLevelData.push({
              id: 1 + i + 1 + "sponsorship",
              title: sponsorshipLevelTwo[i].label,
              value: sponsorshipLevelTwo[i].value,
              color: sponsorshipLevelTwo[i].backgroundColor,
              data: dataLevelThree,
            });
          }
        } else if (ele.name === "Print") {
          secondLevelData = [];
          for (i = 0; i < printLevelTwo.length; i++) {
            let levelthree = printThirdLevelData.find(
              (obj) => obj.title === printLevelTwo[i].label
            ).value;
            dataLevelThree = [];
            for (j = 0; j < levelthree.length; j++) {
              dataLevelThree.push({
                id: 1 + i + 1 + j + "print",
                title: levelthree[j].name,
                value: levelthree[j].value,
                color: this.colors[j],
              });
            }
            secondLevelData.push({
              id: 1 + i + 1 + "print",
              title: printLevelTwo[i].label,
              value: printLevelTwo[i].value,
              color: printLevelTwo[i].backgroundColor,
              data: dataLevelThree,
            });
          }
        }else if (ele.name === "Radio") {
          secondLevelData = [];
          for (i = 0; i < radioLevelTwo.length; i++) {
            let levelthree = radioThirdLevelData.find(
              (obj) => obj.title === radioLevelTwo[i].label
            ).value;
            dataLevelThree = [];
            for (j = 0; j < levelthree.length; j++) {
              dataLevelThree.push({
                id: 1 + i + 1 + j + "radio",
                title: levelthree[j].name,
                value: levelthree[j].value,
                color: this.colors[j],
              });
            }
            secondLevelData.push({
              id: 1 + i + 1 + "radio",
              title: radioLevelTwo[i].label,
              value: radioLevelTwo[i].value,
              color: radioLevelTwo[i].backgroundColor,
              data: dataLevelThree,
            });
          }
        }else if (ele.name === "Billboard") {
          secondLevelData = [];
          for (i = 0; i < billboardLevelTwo.length; i++) {
            let levelthree = billboardThirdLevelData.find(
              (obj) => obj.title === billboardLevelTwo[i].label
            ).value;
            dataLevelThree = [];
            for (j = 0; j < levelthree.length; j++) {
              dataLevelThree.push({
                id: 1 + i + 1 + j + "billboard",
                title: levelthree[j].name,
                value: levelthree[j].value,
                color: this.colors[j],
              });
            }
            secondLevelData.push({
              id: 1 + i + 1 + "billboard",
              title: billboardLevelTwo[i].label,
              value: billboardLevelTwo[i].value,
              color: billboardLevelTwo[i].backgroundColor,
              data: dataLevelThree,
            });
          }
        } else if (ele.name === "Content") {
          secondLevelData = [];
          for (i = 0; i < contentLevelTwo.length; i++) {
            let levelthree = contentThirdLevelData.find(
              (obj) => obj.title === contentLevelTwo[i].label
            ).value;
            dataLevelThree = [];
            for (j = 0; j < levelthree.length; j++) {
              dataLevelThree.push({
                id: 1 + i + 1 + j + "content",
                title: levelthree[j].name,
                value: levelthree[j].value,
                color: this.colors[j],
              });
            }
            secondLevelData.push({
              id: 1 + i + 1 + "content",
              title: contentLevelTwo[i].label,
              value: contentLevelTwo[i].value,
              color: contentLevelTwo[i].backgroundColor,
              data: dataLevelThree,
            });
          }
        }
        data.push({
          id: index + 1,
          title: ele.name,
          value: ele.predicted,
          color: this.colors[index],
          data: secondLevelData,
        });
      });
    this.levelOne = data;
  },
  computed: {
    subsubStyle() {
      let style;
      if (
        this.activeGraph.activeFirst === 0 &&
        this.activeGraph.activeSecond === 0
      ) {
        style = `top: 0px;`;
      } else {
        if (
          (this.activeGraph.activeFirst > 0 &&
            this.activeGraph.activeSecond === 0) ||
          (this.activeGraph.activeFirst > 0 &&
            this.activeGraph.activeSecond > 0)
        ) {
          style =
            `top:` + (336 * (this.activeGraph.activeSecond + (this.activeGraph.activeFirst ))) + `px;`;
        } else {
          // style = `top:`+ (336*(this.activeGraph.activeSecond+1) + 6)+`px;`;
          style = `top:` + (334 * this.activeGraph.activeSecond + 6) + `px;`;
        }
      }
      return style;
    },
    checkLevelThree() {
      var sum = 0;
      for (var i = 0; i < this.levelOne.length; i++) {
        for (var j = 0; j < this.levelOne[i].data.length; j++) {
          sum = 0;
          for (var k = 0; k < this.levelOne[i].data[j].data.length; k++) {
            sum += this.levelOne[i].data[j].data[k].value;
            if (k === this.levelOne[i].data[j].data.length - 1) {
              if (sum !== 100) {
                return {
                  name: this.levelOne[i].data[j].data[k].title,
                  value: sum,
                };
              }
            }
          }
        }
      }
      return { name: "", value: sum };
    },
    checkLevelTwo() {
      var sum = 0;
      for (var i = 0; i < this.levelOne.length; i++) {
        if (this.levelOne[i].title === "Digital") {
          sum = 0;
          for (var j = 0; j < this.levelOne[i].data.length; j++) {
            sum += this.levelOne[i].data[j].value;
            if (j === this.levelOne[i].data.length - 1) {
              if (sum !== 100) {
                return { name: "Digital", value: sum };
              }
            }
          }
        } else if (this.levelOne[i].title === "Content") {
          sum = 0;
          for (j = 0; j < this.levelOne[i].data.length; j++) {
            sum += this.levelOne[i].data[j].value;
            if (j === this.levelOne[i].data.length - 1) {
              if (sum !== 100) {
                return { name: "Content", value: sum };
              }
            }
          }
        }else if (this.levelOne[i].title === "Tv") {
          sum = 0;
          for (j = 0; j < this.levelOne[i].data.length; j++) {
            sum += this.levelOne[i].data[j].value;
            if (j === this.levelOne[i].data.length - 1) {
              if (sum !== 100) {
                return { name: "TV", value: sum };
              }
            }
          }
        }else if (this.levelOne[i].title === "Print") {
          sum = 0;
          for (j = 0; j < this.levelOne[i].data.length; j++) {
            sum += this.levelOne[i].data[j].value;
            if (j === this.levelOne[i].data.length - 1) {
              if (sum !== 100) {
                return { name: "Print", value: sum };
              }
            }
          }
        }else if (this.levelOne[i].title === "Sponsorships") {
          sum = 0;
          for (j = 0; j < this.levelOne[i].data.length; j++) {
            sum += this.levelOne[i].data[j].value;
            if (j === this.levelOne[i].data.length - 1) {
              if (sum !== 100) {
                return { name: "Sponsorships", value: sum };
              }
            }
          }
        }else if (this.levelOne[i].title === "Radio") {
          sum = 0;
          for (j = 0; j < this.levelOne[i].data.length; j++) {
            sum += this.levelOne[i].data[j].value;
            if (j === this.levelOne[i].data.length - 1) {
              if (sum !== 100) {
                return { name: "Radio", value: sum };
              }
            }
          }
        }else if (this.levelOne[i].title === "Billboard") {
          sum = 0;
          for (j = 0; j < this.levelOne[i].data.length; j++) {
            sum += this.levelOne[i].data[j].value;
            if (j === this.levelOne[i].data.length - 1) {
              if (sum !== 100) {
                return { name: "Billboard", value: sum };
              }
            }
          }
        }
      }
      return { name: "", value: sum };
    },
    levelOneChecking() {
      var sum = 0;
      for (var i = 0; i < this.levelOne.length; i++) {
        sum += this.levelOne[i].value;
        if (i === this.levelOne.length - 1) {
          if (sum !== 100) {
            return { name: "Level One", value: sum };
          }
        }
      }
      return { name: "", value: sum };
    },
    isActive() {
      if (
        this.checkLevelThree.value === 100 &&
        this.levelOneChecking.value === 100 &&
        this.checkLevelTwo.value === 100
      ) {
        return "active";
      }
      return "";
    },
  },
  methods: {
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    toggleOpenButton(index) {
      this.activelevelOne = this.levelOne[index].title;
      if (!this.showMap[index]) {
        this.activeGraph.activeFirst = index;
        this.showMap = [];
        this.showMap[index] = true;
        this.breadcrumb.second = "Ad type";
      } else {
        this.activeGraph.activeFirst = null;
        this.showMap[index] = false;
        this.breadcrumb.second = "";
      }
    },
    toggleCloseButton(index) {
      this.activeGraph.activeFirst = null;
      this.showMap[index] = false;
      this.breadcrumb.second = "";
    },
    toggleOpenSubButton(index) {
      if (!this.showMapSub[index]) {
        this.activeGraph.activeSecond = index;
        this.showMapSub = [];
        this.showMapSub[index] = true;
        this.breadcrumb.third = "Campaign type ";
      } else {
        this.activeGraph.activeSecond = null;
        this.showMapSub[index] = false;
        this.breadcrumb.third = "";
      }
      this.activeLevelTwo = this.levelOne[this.activeGraph.activeFirst].data[
        index
      ].title;
    },
    toggleCloseSubButton(index) {
      this.activeGraph.activeSecond = null;
      this.showMapSub = [];
      this.showMapSub[index] = false;
      this.breadcrumb.third = "";
    },
    getSliderValue(e, data) {
      sessionStorage.setItem("edited", "edited");
      this.levelOne.find((obj) => obj.title === data.title).value = e;
      this.levelOneDataEdit.find(
        (obj) => obj.name === data.title
      ).predicted = e;
    },
    getSliderValueLevelTwo(e, details) {
      sessionStorage.setItem("edited", "edited");
      let levelTwoData = this.levelOne.find(
        (obj) => obj.title === this.activelevelOne
      ).data;
      levelTwoData.find((obj1) => obj1.title === details.title).value = e;
      if (this.activelevelOne === "Digital") {
        this.digitalDataLevelTwo.find(
          (objedit) => objedit.label === details.title
        ).value = e;
      } else if (this.activelevelOne === "Content") {
        this.contentDataLevelTwo.find(
          (objedit) => objedit.label === details.title
        ).value = e;
      }else if (this.activelevelOne === "Tv") {
        this.tvDataLevelTwo.find(
          (objedit) => objedit.label === details.title
        ).value = e;
      }else if (this.activelevelOne === "Sponsorships") {
        this.sponsorshipDataLevelTwo.find(
          (objedit) => objedit.label === details.title
        ).value = e;
      }else if (this.activelevelOne === "Print") {
        this.printDataLevelTwo.find(
          (objedit) => objedit.label === details.title
        ).value = e;
      }else if (this.activelevelOne === "Radio") {
        this.radioDataLevelTwo.find(
          (objedit) => objedit.label === details.title
        ).value = e;
      }else if (this.activelevelOne === "Billboard") {
        this.billboardDataLevelTwo.find(
          (objedit) => objedit.label === details.title
        ).value = e;
      }
    },
    getSliderValueLevelThree(e, details) {
      sessionStorage.setItem("edited", "edited");
      let levelTwoData = this.levelOne.find(
        (obj) => obj.title === this.activelevelOne
      ).data;
      let levelThreeData = levelTwoData.find(
        (obj1) => obj1.title === this.activeLevelTwo
      ).data;
      levelThreeData.find((obj3) => obj3.title === details.title).value = e;
      if (this.activelevelOne === "Digital") {
        let dataEdit = this.digitalDataThirdLevel.find(
          (title) => title.title === this.activeLevelTwo
        ).value;
        dataEdit.find((editObj) => editObj.name === details.title).value = e;
      } else if (this.activelevelOne === "Content") {
        let dataEdit = this.contentDataThirdLevel.find(
          (title) => title.title === this.activeLevelTwo
        ).value;
        dataEdit.find((editObj) => editObj.name === details.title).value = e;
      }else if(this.activelevelOne === "Tv"){
        let dataEdit = this.tvDataThirdLevel.find(
          (title) => title.title === this.activeLevelTwo
        ).value;
        dataEdit.find((editObj) => editObj.name === details.title).value = e;
      }else if(this.activelevelOne === "Sponsorships"){
        let dataEdit = this.sponsorshipDataThirdLevel.find(
          (title) => title.title === this.activeLevelTwo
        ).value;
        dataEdit.find((editObj) => editObj.name === details.title).value = e;
      }else if(this.activelevelOne === "Print"){
        let dataEdit = this.printDataThirdLevel.find(
          (title) => title.title === this.activeLevelTwo
        ).value;
        dataEdit.find((editObj) => editObj.name === details.title).value = e;
      }else if(this.activelevelOne === "Radio"){
        let dataEdit = this.radioDataThirdLevel.find(
          (title) => title.title === this.activeLevelTwo
        ).value;
        dataEdit.find((editObj) => editObj.name === details.title).value = e;
      }else if(this.activelevelOne === "Billboard"){
        let dataEdit = this.billboardDataThirdLevel.find(
          (title) => title.title === this.activeLevelTwo
        ).value;
        dataEdit.find((editObj) => editObj.name === details.title).value = e;
      }
    },
    handleClick() {
      this.$router.push({
        name: "AIMO-IN-Platform-Planning-Result",
        params: {
          digitalData: this.digitalDataLevelTwo,
          tvData: this.tvDataLevelTwo,
          sponsorshipData: this.sponsorshipDataLevelTwo,
          printData: this.printDataLevelTwo,
          radioData: this.radioDataLevelTwo,
          contentData: this.contentDataLevelTwo,
          levelOneData: this.levelOneDataEdit,
          digitalThirdLevelData: this.digitalDataThirdLevel,
          tvThirdLevelData: this.tvDataThirdLevel,
          sponsorshipThirdLevelData:this.sponsorshipDataThirdLevel,
          printThirdLevelData:this.printDataThirdLevel,
          radioThirdLevelData:this.radioDataThirdLevel,
          contentThirdLevelData: this.contentDataThirdLevel,
        },
      });
    },
  },
};
</script>

<style scoped>
.w-99 {
  width: 99%;
}
.chart-container {
  background: transparent !important;
}
.caption {
  height: 20px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #727988;
}
.newcard {
  width: 312px;
  height: 312px;
  margin: 24px;
  padding: 17px 0px;
  object-fit: contain;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #fff;
  margin: auto;
  margin-bottom: 24px;
}
.slider_text {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
}
.slider_button_right {
  background: #f3f3f3;
  padding: 5px;
  border-radius: 4px;
}
.text-normal {
  font-weight: normal;
  font-size: 16px;
}
.bottom_header {
  position: sticky;
  top: 100px;
  z-index: 8;
  background: #f2f5fa;
  box-shadow: rgb(233 235 239) 0px 9px 9px 0px;
}
.bottom_buttom {
  width: 100%;
  text-align: end;
  position: sticky;
  bottom: 0;
  z-index: 10;
  background: #f2f5fa;
}
.btn-effect {
  margin: 20px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #cdcdcd;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}
.btn-effect-valid {
  margin: 20px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect-valid i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect:hover,
.btn-effect.active {
  background-color: #050505;
  color: #f3f3f3;
}
.border_right_side::before {
  content: "";
  border-top: 2px solid #000;
  display: block;
  position: relative;
  left: 100%;
  top: 50%;
  width: 28%;
}
.border_left_side::after {
  content: "";
  border-top: 2px solid #000;
  display: block;
  position: relative;
  left: -28%;
  top: -54%;
  width: 28%;
}
.border-dark {
  border: 2px solid #000 !important;
}
.disabled_card {
  background: #ffffff;
  opacity: 0.5;
}
.disabled_card.default {
  opacity: 1;
  background: #fff;
}
.divider {
  border-right: 2px solid #000;
  top: -54%;
  display: block;
  left: 28%;
  position: relative;
}
.text-bold {
  font-weight: bold;
}
.card__body_content {
  overflow-y: auto;
  height: 85%;
  overflow-x: auto;
  box-shadow: rgb(233 235 239) -2px 4px 20px 11px;
  width: 100%;
}
.fix-width {
  max-width: 1600px;
  width: 100%;
}
.prediction-section {
  overflow: hidden;
  height: 70%;
  padding: 0 10px;
}
@media screen and (max-width: 640px) {
  .prediction-section {
    padding: 0 15px !important;
  }
}
@media screen and (max-width: 768px) {
  .prediction-section {
    padding: 0 15px !important;
  }
}
</style>
